import { Name } from './campaign/names';

/** *** *** *** *** *** ***
 * Types
 *** *** *** *** *** *** */
export type DyApiContextPageType = 'PRODUCT' | 'HOMEPAGE' | 'CATEGORY' | 'POST' | 'CART' | 'OTHER';

export enum DyContextPageType {
  PRODUCT = 'PRODUCT',
  HOMEPAGE = 'HOMEPAGE',
  CATEGORY = 'CATEGORY',
  CART = 'CART',
  OTHER = 'OTHER',
}

export type ValidDYCookies = {
  _dy_cf_variation: string | null;
  _dyid_server: string | null;
  _dyid: string | null;
  _dyjsession: string | null;
};

export type DYApiCookieData = {
  name: string;
  value: string;
  maxAge: number;
};

type DYApiAnalyticsMetadata = {
  campaignId: number;
  campaignName: string;
  experienceId: number;
  experienceName: string;
  variationId: number;
  variationName: string;
};

type CustomCodeApiVariation = {
  id: number;
  payload: IVedCampaignTemplatePayload;
  analyticsMetadata?: DYApiAnalyticsMetadata;
};

type RecommendationApiVariation = {
  id: number;
  payload: IVedRecommendationPayload;
  analyticsMetadata?: DYApiAnalyticsMetadata;
};

export type DYApiChoice = {
  id: number;
  name: string;
  type: 'DECISION' | 'RECS_DECISION';
  variations: (CustomCodeApiVariation | RecommendationApiVariation)[];
  groups?: string[];
  decisionId: string;
};

export type DYApiPage = {
  type: DyApiContextPageType;
  data: any[];
  location?: string;
  locale?: string;
};

export type DyApiDecision = 'DECISION' | 'RECS_DECISION';

export type DYApiWarning = {
  code: string;
  message: string;
};

export type DYApiUser = {
  dyid: string;
  dyid_server: string;
};

export type DYApiSession = {
  dy: string;
};

export type CampaignPathData = { slugs: null | '*' | string[]; campaigns: Name[] };

export type CampaignSlugAndName = { slug: string | null; name: Name | null };

export type ICampaignVariationsConfig = Record<Name, IVedCampaignTemplatePayload['data'][]>;

export type PathSegmentData = { basePath: string; segments: string[]; slug: string | null };

export type VedCampaignPayload = IVedCampaignTemplatePayload['data'] & Record<string, any>;

export type VedVariationCookie = {
  campaignName: string;
  variation: IVedCampaignTemplatePayload['data'];
};

type RequiredKeys<T> = {
  [K in keyof T]-?: Record<string, unknown> extends Pick<T, K> ? never : K;
}[keyof T];

type DYFilterAction = 'IS' | 'IS_NOT' | 'CONTAINS' | 'GE' | 'GTE' | 'LE' | 'LTE' | 'EQ';

type DYRecommendationApiFilterArgument = {
  action: DYFilterAction;
  value: string | number;
};

type DYRecommendationApiFilter = {
  field: string;
  arguments: DYRecommendationApiFilterArgument[];
};

export type DyRecommendationApiBody = {
  campaign: string;
  filters: DYRecommendationApiFilter[];
  page: DYApiPage;
  options?: IDyRecommendationApiOptionsBody;
};

export type DYRecommendationFilterField = 'keywords' | 'categories' | 'price';

export type DYRecommendationConditionArgument = {
  action: DYFilterAction;
  value: string | number;
};

export type DYRecommendationApiCondition = {
  field: DYRecommendationFilterField;
  arguments: DYRecommendationConditionArgument[];
};

export type DYRecommendationApiBody = {
  selector: {
    names: string[];
    groups?: string[];
    args: {
      [key: string]: {
        realtimeRules: {
          type: 'include';
          slots?: [];
          query: {
            conditions: DYRecommendationApiCondition[];
          };
        }[];
      };
    };
  };
  context: IDyApiContextBody;
  events?: [];
  options?: IDyRecommendationApiOptionsBody;
  user?: DYApiUser;
  session?: DYApiSession;
};

export type DYRecommendationConditions = {
  recipient?: string;
  personality: string[];
  price: {
    from: number;
    to: number;
  };
};

export type DYRecommendationRequestData = {
  campaign: string;
  conditions: DYRecommendationConditions;
  path: string;
  dyCookies: Record<string, string>;
  options?: IDyRecommendationApiOptionsBody;
};

type DYProductData = {
  alt_image_urls: string;
  categories: string[];
  descriptions: string;
  dy_display_price: string;
  group_id: string;
  image_url: string;
  in_stock: boolean;
  keywords: string[];
  locations: string;
  margin_amount: string;
  margin_band: string;
  name: string;
  new_product: 'True' | 'False';
  number_of_participants: string;
  number_of_reviews: string;
  on_offer: 'True' | 'False';
  price: number;
  product_id: string;
  publish_time: string;
  reviews_rating: string;
  saving_amount: string;
  saving_band: string;
  sub_cat_image_url: string;
  sub_cat_name: string;
  sub_cat_url: string;
  supplier: string;
  top_cat_image_url: string;
  top_cat_name: string;
  top_cat_url: string;
  url: string;
  validity: string;
  was_price: string;
  whats_included: string;
  is_collection_product: boolean;
};

export type DYProductSlot = {
  sku: string;
  productData?: DYProductData;
  slotId: string;
};

export type Product = {
  sku: string;
  slug: string;
  title: string;
  displayPrice: number;
  src: string;
  alt?: string;
  shortDescription?: string;
  locations?: number;
  rrp?: number;
  percentOff?: number;
  totalReviews?: number;
  averageRating?: number;
  inStock?: boolean;
  isNew?: boolean;
  isOnSale?: boolean;
  isCollectionProduct?: boolean;
  additionalImages?: string;
  categories: string[];
  keywords: string[];
};

export type ProductRequired = RequiredKeys<Product>;

/** *** *** *** *** *** ***
 * Extendible Interfaces
 *** *** *** *** *** *** */
export interface IVedCampaignTemplatePayload {
  type: 'CUSTOM_JSON';
  data: {
    variation: string;
    defaultVariation: 'true' | 'false';
    [key: string]: any;
  };
}

export interface IVedRecommendationPayload {
  type: 'RECS';
  data: {
    slots: DYProductSlot[];
  };
}

export interface IDyApiSelectorBody {
  names: string[];
  groups?: string[];
}

export interface IDyApiContextBody {
  page: DYApiPage;
}

export interface IDyApiOptionsBody {
  isImplicitPageview?: boolean;
  returnAnalyticsMetadata?: boolean;
}

export interface IDyRecommendationApiOptionsBody extends IDyApiOptionsBody {
  recsProductData?: {
    skusOnly?: boolean;
  };
}

type CategoryCampaignStructure = {
  paths: CampaignPathData[];
  plp: {
    paths: CampaignPathData[];
  };
};

// interface for the pathsConfig object - update paths as implemented
export interface ICampaignPathConfig {
  /**
   * homepage
   */
  _: { paths: CampaignPathData[] };
  /**
   * category, paginated, and plp
   */
  c: CategoryCampaignStructure;
  /**
   * collection and collection choices
   */
  collection: {
    paths: CampaignPathData[];
    '[slug]': {
      product: {
        paths: CampaignPathData[];
      };
    };
  };
  /**
   * category override
   */
  cor: CategoryCampaignStructure;
  /**
   * blog
   */
  'experience-blog'?: null;
  /**
   * feature
   */
  f: CategoryCampaignStructure;
  /**
   * location
   */
  location: CategoryCampaignStructure;
  /**
   * occasion
   */
  o: CategoryCampaignStructure;
  /**
   * price
   */
  price: CategoryCampaignStructure;
  /**
   * product
   */
  product: { paths: CampaignPathData[] }; // product
  /**
   * rating
   */
  rating: CategoryCampaignStructure;
  /**
   * search
   */
  search?: null;
  /**
   * things-to-do
   */
  'things-to-do'?: null;
  /**
   * gift-finder
   */
  'gift-finder': {
    results: { paths: CampaignPathData[] };
  };
}

export interface ICampaign {
  config: ICampaignPathConfig;
  variations: Record<Name, IVedCampaignTemplatePayload['data'][]>;
  names: Name[];
}

/** *** *** *** *** *** ***
 * Class Interfaces
 *** *** *** *** *** *** */
export interface IDyAPI {
  choose(
    path: string,
    dYCookies: Record<string, string>,
    options?: IDyApiOptionsBody
  ): Promise<{
    choice: IVedCampaignTemplatePayload['data'];
    cookies: DYApiCookieData[];
    campaignName?: string;
  }>;

  pageView(
    path: string,
    dYCookies: Record<string, string>,
    options?: IDyApiOptionsBody
  ): Promise<void>;

  recommendation(
    requestData: DYRecommendationRequestData
  ): Promise<{ choices: Product[] | string[]; cookies: DYApiCookieData[] }>;

  getPageType(path: string): DyApiContextPageType;
}

export interface IDyBuild {
  generateSSGPaths(
    path: string,
    slugs: string[],
    /**
     * An optional pagination prefix string to append to slugs, e.g. page/2/
     */
    paginationPrefix?: string
  ): string[];
}
