import { RECOMMENDATION_CONDITION_LIMIT } from './constants';

import type {
  DYApiChoice,
  DYApiCookieData,
  DYProductSlot,
  DYRecommendationApiCondition,
  DYRecommendationConditions,
  DyApiContextPageType,
  IVedCampaignTemplatePayload,
  IVedRecommendationPayload,
  Product,
  ProductRequired,
  DYRecommendationConditionArgument,
} from '../../types';
import { CategoryPathTypes } from '../shared/path-types';

import { SharedUtils } from '../shared/utils';

export class DyApiUtils extends SharedUtils {
  /**
   * Filters and returns an array of valid choices by checking if each choice has a non-empty string decisionId property.
   * This is used to filter out invalid choices received from the API.
   */
  protected filterValidChoices(choices: DYApiChoice[]): DYApiChoice[] {
    if (!Array.isArray(choices)) return [];
    return choices.filter((choice) => typeof choice?.decisionId === 'string');
  }

  /**
   * Validates and returns an array of cookies by checking if it's a valid array.
   * This is used to validate the cookies received from the API.
   */
  protected validateCookies(cookies: DYApiCookieData[]): DYApiCookieData[] {
    if (!Array.isArray(cookies)) return [];
    const validCookies = cookies.filter(
      (cookie) => cookie?.name && cookie?.value && cookie?.maxAge
    );
    return validCookies;
  }

  /**
   * Extracts and returns the variation data from the first valid choice.
   * The variation can be either a campaign template payload or a recommendation template payload.
   * This is used to extract the relevant variation data from the API response.
   */
  protected extractVariationFromChoice(
    choices: DYApiChoice[]
  ): IVedCampaignTemplatePayload['data'] | IVedRecommendationPayload['data'] | null {
    if (!Array.isArray(choices) || !choices.length) return null;

    const choice = choices?.[0];
    if (!Array.isArray(choice.variations) || !choice.variations.length) return null;

    const data = choice?.variations?.[0]?.payload?.data;
    if (!data) return null;

    switch (choice.type) {
      case 'DECISION':
        return data as IVedCampaignTemplatePayload['data'];
      case 'RECS_DECISION':
        return data as IVedRecommendationPayload['data'];
    }
  }

  /**
   * Extracts and returns the product slots from the first valid choice.
   * This is used to extract the relevant product data from the Recommendation API response.
   */
  protected extractProductSlotsFromChoice(choices: DYApiChoice[]): DYProductSlot[] | null {
    const data = this.extractVariationFromChoice(choices) as IVedRecommendationPayload['data'];

    if (!data?.slots || !Array.isArray(data.slots)) return null;

    return data.slots;
  }

  /**
   * Returns the page type based on the given path base segment (e.g. c, product, experience-blog, etc.).
   * @param pathFirstSegment - The path base segment to determine the page type from.
   * @returns The page type as a DyApiContextPageType enum value.
   */
  protected getPageTypeFromPath(pathFirstSegment: string): DyApiContextPageType {
    const categories: string[] = Object.values(CategoryPathTypes);
    if (categories.includes(pathFirstSegment)) return 'CATEGORY';

    switch (pathFirstSegment) {
      case '_':
        return 'HOMEPAGE';
      case 'product':
        return 'PRODUCT';
      case 'experience-blog':
        return 'POST';
      default:
        return 'OTHER';
    }
  }

  /**
   * Builds an array of recommendation conditions based on the provided `DYRecommendationConditions` object.
   * @param data An object containing data used to build the recommendation conditions.
   * @returns An array of `DYRecommendationApiCondition` objects representing the recommendation conditions.
   */
  protected buildRecommendationConditions(
    data: DYRecommendationConditions
  ): DYRecommendationApiCondition[] {
    const conditions: DYRecommendationApiCondition[] = [];

    if (data?.recipient) {
      conditions.push({
        field: 'keywords',
        arguments: [{ action: 'CONTAINS', value: data.recipient.trim() }],
      });
    }
    if (data?.price?.from) {
      conditions.push({ field: 'price', arguments: [{ action: 'GTE', value: data.price.from }] });
    }
    if (data?.price?.to) {
      conditions.push({ field: 'price', arguments: [{ action: 'LTE', value: data.price.to }] });
    }

    const uniquePersonalities = new Set(
      data?.personality?.filter((personality) => !!personality.trim())
    );
    const personalityArguments: DYRecommendationConditionArgument[] = [];
    const omittedValues: string[] = [];

    uniquePersonalities.forEach((personality) => {
      const conditionCount = conditions.length + personalityArguments.length;
      if (conditionCount < RECOMMENDATION_CONDITION_LIMIT) {
        personalityArguments.push({ action: 'CONTAINS', value: personality.trim() });
      } else {
        omittedValues.push(personality);
      }
    });

    if (omittedValues.length > 0) {
      console.warn(
        `[DY API Warning]: Recommendation only supports up to ${RECOMMENDATION_CONDITION_LIMIT} conditions, some personality keywords have been omitted: ${omittedValues.join(
          ', '
        )}`
      );
    }

    if (personalityArguments.length > 0) {
      conditions.push({ field: 'keywords', arguments: personalityArguments });
    }

    return conditions;
  }

  /**
   * Maps an array of `DYProductSlot` objects to an array of `Product` objects.
   * @param dyProductSlots An array of `DYProductSlot` objects.
   * @returns An array of `Product` objects.
   */
  protected mapProductSlotsToProducts(dyProductSlots: DYProductSlot[]): Product[] {
    const products: Product[] = [];

    dyProductSlots.forEach(({ sku, productData }) => {
      const { slug } = this.segmentsFromPath(productData.url);
      const name = this.parseString('name', productData?.name);
      const additionalImages = this.parseString('alt_image_urls', productData?.alt_image_urls);

      const product: Product = {
        sku,
        slug,
        title: name,
        alt: name,
        src: this.parseString('image_url', productData?.image_url),
        shortDescription: this.parseString('descriptions', productData?.descriptions),
        averageRating: this.parseIntString('reviews_rating', productData?.reviews_rating),
        displayPrice: this.parseIntString('dy_display_price', productData?.dy_display_price, true),
        rrp: this.parseIntString('was_price', productData?.was_price, true),
        percentOff: this.parseIntString('saving_amount', productData?.saving_amount, true),
        locations: this.parseIntString('locations', productData?.locations),
        totalReviews: this.parseIntString('number_of_reviews', productData?.number_of_reviews),
        isNew: this.parseBoolString('new_product', productData?.new_product),
        isOnSale: this.parseBoolString('on_offer', productData?.on_offer),
        inStock: this.parseBoolean('in_stock', productData?.in_stock),
        isCollectionProduct: this.parseBoolean(
          'is_collection_product',
          productData?.is_collection_product
        ),
        additionalImages,
        categories: Array.isArray(productData?.categories) ? productData.categories : [],
        keywords: Array.isArray(productData?.keywords) ? productData.keywords : [],
      };

      // Only add the product if it contains all required fields
      if (this.validateProduct(product)) {
        products.push(product);
      } else {
        console.warn(
          `[DY API Warning]: Product(${
            product.sku || product.title
          }) failed validation and will be omitted from returned products`
        );
      }
    });

    return products;
  }

  /**
   * Validates a product by checking if it contains all required fields.
   * Logs warnings if any fields are missing.
   * @param product The product to validate.
   * @returns A boolean indicating whether the product is valid or not.
   */
  private validateProduct(product: Product): boolean {
    const requiredFields: ProductRequired[] = ['sku', 'slug', 'src', 'title', 'displayPrice'];
    const missingRequiredFields = [];

    requiredFields.forEach((field) => {
      const value = product[field];
      if (value === undefined || value === null || value === '') missingRequiredFields.push(field);
    });

    // Return false if any required fields are missing
    if (missingRequiredFields.length > 0) {
      console.warn(
        `[DY API Warning]: Product(${
          product.sku || product.title
        }) missing required field(s): ${missingRequiredFields.join(', ')}`
      );

      return false;
    }

    return true;
  }

  /**
   * @param key The key to use in the warning message.
   * @param value The string to parse.
   * @param parseToFloat Whether to parse the string to a float instead of an integer.
   * @returns The parsed integer or undefined if cannot parse input.
   */
  private parseIntString(key: string, value: string, parseToFloat?: boolean): number | undefined {
    if (value === undefined || value === null || value === '') return undefined;

    const parsedInt = parseToFloat ? parseFloat(value) : parseInt(value);

    if (isNaN(parsedInt)) {
      console.warn(`[DY API Warning]: Cannot parse integer string: ${key}=${value}`);
      return undefined;
    }

    return parsedInt;
  }

  /**
   * @param key The key to use in the warning message.
   * @param value The string to parse.
   * @returns The parsed boolean or undefined if cannot parse input.
   */
  private parseBoolString(key: string, value: string): boolean | undefined {
    if (value === undefined || value === null || value === '') return undefined;

    if (typeof value === 'string') {
      const valueLower = value.toLowerCase();
      if (valueLower === 'true') return true;
      if (valueLower === 'false') return false;
    }

    console.warn(`[DY API Warning]: Cannot parse boolean string: ${key}=${value}`);
    return undefined;
  }

  /**
   * @param key The key to use in the warning message.
   * @param value The string to parse.
   * @returns The parsed string or undefined if cannot parse input.
   */
  private parseString(key: string, value: string): string | undefined {
    if (value === undefined || value === null || value === '') return undefined;

    if (typeof value !== 'string' || !value.trim()) {
      console.warn(`[DY API Warning]: Cannot parse string: ${key}=${value}`);
      return undefined;
    }

    return value.trim();
  }

  /**
   * @param key The key to use in the warning message.
   * @param value The boolean to parse.
   * @returns The parsed boolean or undefined if cannot parse input.
   */
  private parseBoolean(key: string, value: boolean): boolean | undefined {
    if (value === undefined || value === null) return undefined;

    if (typeof value === 'boolean') return value;

    console.warn(`[DY API Warning]: Cannot parse boolean: ${key}=${value}`);
    return undefined;
  }
}
